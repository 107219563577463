<template>
  <div>

    <div class="container-fluid py-5 bg-a1">
      <div class="row d-flex py-5 justify-content-center">
        <div class="col-sm-8 pt-5 text-center text-white">
          
          <h2 class="mb-0"><strong>Academic History</strong></h2>

          <p class="my-5 h1"><i class="fad fa-file-certificate"></i></p>
          
          <div class="py-5">
            <p class="h4">Professional Technical Bachelor in Informatics</p>
            <p class="h5 text-muted">Conalep Tlaquepaque</p>
            <p class="h5 text-muted">Professional Licence 11367519</p>
            <p class="h6 text-muted">2015 - 2018</p>
          </div>

          <div class="py-5">
            <p class="h4">Information Security and Network Engineering</p>
            <p class="h5 text-muted">ITESO</p>
            <p class="h6 text-muted">2018 - 2023</p>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>