<template>
  <div>

    <div class="container-fluid py-5">
      <div class="row d-flex py-5 justify-content-center">
        <div class="col-sm-8 pt-5 text-center text-a1">

          <h2 class="mb-0 text-a1"><strong>Laboral Experience</strong></h2>
          
          <p class="my-5 h1 text-a2"><i class="fad fa-briefcase"></i></p>

          <div v-for="(item, key) in experience" :key="key"
            class="py-5">
            <p class="h4"><strong>{{ item.title }}</strong></p>
            <p class="h5 text-muted">{{ item.position }}</p>
            <p class="h6 text-muted">{{ item.contact }}</p>
            <p class="h6 text-muted">{{ item.description }}</p>
            <p class="h6 text-muted">{{ item.interval_date }}</p>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      experience: ''
    }
  },
  mounted() {
    
    this.experience = [
      {
        title: 'Diseños e Impresiones Albert',
        position: 'Graphic Design and Advertising',
        description: 'Print and digital advertising design',
        interval_date: 'August 2017 - August 2018',
        contact: '',
      },
      {
        title: 'X-orsystem SA de CV',
        position: 'FullStack Development',
        description: 'Payment and customer support system development.',
        interval_date: 'August 2018 - March 2021',
        contact: '',
      },
      {
        title: 'Paymho',
        position: 'Frontend Development',
        description: 'Frontend development mobile application.',
        interval_date: 'August 2019 - October 2019',
        contact: '',
      },
      {
        title: 'Alfabai',
        position: 'FullStack Development',
        description: 'Accounting and human resources system development.',
        interval_date: 'December 2020 - August 2021',
        contact: '',
      },
      {
        title: 'Menita Labs',
        position: 'Frontend Development',
        description: 'System development for external and internal clients.',
        interval_date: 'August 2021 to current date',
        contact: '',
      },
    ]

  },
}
</script>