<template>
  <div class="my-5 py-5">

    <p class="mb-0 pb-3 h2 text-a2"><i :class="data.icon"></i></p>
    <p class="mb-5 pb-5 text-a1"><strong>{{ data.title }}</strong></p>

    <div class="row py-2" v-for="(item, key) in data.items" :key="key">
      
      <div v-if="side === 'left'" class="col-sm-4 text-a1">
        <div class="row">
          <div class="col-2 text-start text-a2"><i class="fad fa-caret-right"></i></div>
          <div class="col-10 text-end">{{ item.title }}</div>
        </div>
      </div>

      <div class="col-sm-8 my-auto">
        <div class="progress">
          <div class="progress-bar progress-bar-striped bg-a3"
            role="progressbar" 
            :style="'width: ' + item.percentage" 
            aria-valuenow="50" 
            aria-valuemin="0" 
            aria-valuemax="100"
          ></div>
        </div>
      </div>

      <div v-if="side === 'right'" class="col-sm-4 text-a1">
        <div class="row">
          <div class="col-10 text-start">{{ item.title }}</div>
          <div class="col-2 text-end text-a2"><i class="fad fa-caret-left"></i></div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  props: [ 'data', 'side' ]
}
</script>