<template>
  <div>

    <div class="container-fluid py-5 bg-a1">
      <div class="row d-flex py-5 justify-content-center">
        <div class="col-sm-8 pt-5 text-center text-white">
          
          <h4 class="mb-0 py-5"><strong>Certificates and Licences</strong></h4>

          <p class="mb-0 pb-5 h2"><i class="fad fa-award"></i></p>
          
          <div class="pb-5">
            <p class="h4">Graded Examinations in Spoken English</p>
            <p class="h5 text-muted">Trinity College of London</p>
            <p class="h5 text-muted">CEFR Level B1.2 with Merit</p>
            <p class="h6 text-muted">October 2016</p>
          </div>

          <div class="py-5">
            <p class="h4">UX/UI Design</p>
            <p class="h5 text-muted">Coderhouse</p>
            <p class="h6 text-muted">December 2021</p>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>